import instance from "@/axios"

/** 获取收货明细列表 /dq_admin/qualityReturn/listData*/
export const getSendProductList = async (params) => {
    return instance({
        url: '/dq_admin/qualityReturn/listData',
        method: 'GET',
        params: params
    })
}

/** 获取打印数据 /zhandian/sender/kuaidiCreatePrint*/
export const getSendProductPrintData = async (params) => {
    return await instance({
        url: '/dq_admin/qualityReturn/kuaidiCreatePrint',
        method: 'GET',
        params: params
    })
}