<template>
  <div>
    <a-modal
        :width="800"
        v-model="showPopup"
        title="打印预览"
    >
      <template #footer></template>
      <div class="preview-content">
        <div class="print-product-list">
          <div class="print-product-btn" v-if="false">
            <a-button type="primary" @click="handleConfirmPrintProduct">打印产品</a-button>
          </div>
          <VueEasyPrint ref="vueEasyPrintByProductEl" :table-show="true" :onePageRow="onePageRow">
            <VueBarCode
                v-if="false"
                class="ml-38"
                :value="codeData.barCode"
                height="63"
                :flat="true"
                :text-margin="0"
                font-size="20"
                format="EAN13"
                font-options="bold"
            />
            <div class="print-product-text-list">
              <div
                  v-for="(item, index) in codeData"
                  :key="index"
                  class="print-product-item"
              >{{ item.productName + '-' + item.barCodeNum }}</div>
            </div>
          </VueEasyPrint>
        </div>
        <div class="print-express-order">
          <div class="print-express-btn">
            <a-button type="primary" @click="handleConfirmPrintExpress">打印快递</a-button>
          </div>
          <VueEasyPrint ref="vueEasyPrintByExpressEl" :table-show="true" :onePageRow="onePageRow">
            <div :style="{width: printWidth + 'mm', height: printHeight + 'mm'}">
              <img
                  v-if="codeData[0] && codeData[0].expressLabelUrl"
                  class="express-img"
                  :style="{width: printWidth + 'mm', height: printHeight + 'mm'}"
                  :src="codeData[0].expressLabelUrl" alt=""
              />
            </div>
          </VueEasyPrint>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import VueEasyPrint from 'vue-easy-print'
import VueBarCode from 'vue-barcode'
import {getSendProductPrintData} from "@/views/cmsPage/checkManage/returnList/_apis"

export default {
  components: {VueEasyPrint, VueBarCode},
  data() {
    return {
      showPopup: false,
      printArray: [],
      codeData: [],
      printWidth: '',
      printHeight: '',
      onePageRow: 1,
      params: {
        ratingIds: ''
      }
    }
  },
  mounted() {
    this.computedDPI()
  },
  methods: {
    /** 计算像素 */
    computedDPI() {
      this.printWidth = 76
      this.printHeight = 130
    },
    async show(data) {
      this.params.ratingIds = data
      await this.getPrintBarCode()
    },
    /** 获取打印数据 */
    async getPrintBarCode() {
      this.$loading.show()
      const res = await getSendProductPrintData(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.codeData = res.data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.showPopup = false
    },
    /** 打印快递面单 */
    handleConfirmPrintExpress() {
      this.$refs.vueEasyPrintByExpressEl.print()
    },
    /** 打印产品数据 */
    handleConfirmPrintProduct() {
      this.$refs.vueEasyPrintByProductEl.print()
    },
  }
}
</script>
<style scoped lang="scss">
@media print {
  @page {
    size:  76mm 130mm;
    margin: 0;
    padding: 0;
  }

  .noprint {
    display: none;
  }
}
.print-express-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.print-product-text-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  .print-product-item {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
}
.print-product-list {
  width: 100%;
}
.preview-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
}
</style>