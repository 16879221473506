var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 500, title: "面单" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return undefined
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c("div", { staticClass: "preview-content" }, [
            _c(
              "div",
              { staticClass: "print-express-order" },
              [
                _c(
                  "div",
                  { staticClass: "print-express-btn" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleConfirmPrintExpress }
                      },
                      [_vm._v("打印快递")]
                    )
                  ],
                  1
                ),
                _c(
                  "VueEasyPrint",
                  {
                    ref: "vueEasyPrintByExpressEl",
                    attrs: { "table-show": true, onePageRow: _vm.onePageRow }
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          width: _vm.printWidth + "mm",
                          height: _vm.printHeight + "mm",
                          padding: "10mm"
                        }
                      },
                      [
                        _vm.dataUrl
                          ? _c("img", {
                              staticClass: "express-img",
                              style: {
                                width: _vm.printWidth + "mm",
                                height: _vm.printHeight + "mm"
                              },
                              attrs: { src: _vm.dataUrl, alt: "" }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }