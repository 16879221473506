<template>
  <div>
    <a-modal
        :width="500"
        v-model="showPopup"
        title="面单"
    >
      <template #footer></template>
      <div class="preview-content">
        <div class="print-express-order">
          <div class="print-express-btn">
            <a-button type="primary" @click="handleConfirmPrintExpress">打印快递</a-button>
          </div>
          <VueEasyPrint ref="vueEasyPrintByExpressEl" :table-show="true" :onePageRow="onePageRow">
            <div :style="{width: printWidth + 'mm', height: printHeight + 'mm', padding: '10mm'}">
              <img
                  v-if="dataUrl"
                  class="express-img"
                  :style="{width: printWidth + 'mm', height: printHeight + 'mm'}"
                  :src="dataUrl" alt=""
              />
            </div>
          </VueEasyPrint>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import VueEasyPrint from 'vue-easy-print'

export default {
  components: {VueEasyPrint},
  data() {
    return {
      showPopup: false,
      dataUrl: '',
      printWidth: '',
      printHeight: '',
      onePageRow: 1,
    }
  },
  mounted() {
    this.computedDPI()
  },
  methods: {
    /** 计算像素 */
    computedDPI() {
      this.printWidth = 76
      this.printHeight = 130
    },
    async show(data) {
      this.dataUrl = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.showPopup = false
    },
    /** 打印快递面单 */
    handleConfirmPrintExpress() {
      this.$refs.vueEasyPrintByExpressEl.print()
    },
  }
}
</script>
<style scoped lang="scss">
@media print {
  @page {
    size:  76mm 130mm;
    margin: 0;
    padding: 0;
  }

  .noprint {
    display: none;
  }
}
.print-express-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.print-product-text-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  .print-product-item {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
}
.print-product-list {
  width: 100%;
}
.preview-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
}
</style>