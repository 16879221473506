var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        staticClass: "table-box",
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          },
          rowClassName: _vm.rowClassName
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "selectedSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "selected-box" },
                  [
                    _c("a-checkbox", {
                      staticClass: "checked",
                      attrs: {
                        disabled: row.senderStatus === 1,
                        checked: row.checkStatus
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleChangeCheckStatus($event, row)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemProductNameAndImgSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(row.productName))
                ]),
                _c(
                  "div",
                  { staticClass: "flex-start-center flex-wrap img-list mt-10" },
                  _vm._l(row.productImage, function(item, index) {
                    return _c("img", {
                      key: index,
                      attrs: { src: item, alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.handlePreviewImg(row.productImage, index)
                        }
                      }
                    })
                  }),
                  0
                )
              ])
            }
          },
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    staticClass: "cur-pot color-blue",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToOrder(row.orderNo)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.orderNo))]
                )
              ])
            }
          },
          {
            key: "itemTakeOverTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.sendProductClassType.get(row.senderStatus) },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedSendProductTypeMapText(row.senderStatus)
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemInsuredSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(row.ratingToBuyerPrice))])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.senderStatus === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowExpressImg(
                                  row.expressShortUrl
                                )
                              }
                            }
                          },
                          [_vm._v("查看面单")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          }
        ])
      }),
      _c("ShowProductExpressNoPopup", { ref: "showProductExpressNoPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }