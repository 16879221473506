<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.barCode"
          class="w-200"
          placeholder='输入裸币条码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.shortCode"
          class="w-200 ml-5"
          placeholder='输入简短编码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.orderNo"
          class="w-200 ml-5"
          placeholder='订单号'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="ml-5 w-250"
          v-model="params.senderStatus"
          placeholder="发货类型"
      >
        <a-select-option
            v-for="item of takeOVerProductTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-input
          allowClear
          v-model="params.senderExpressNo"
          class="w-200 ml-5"
          placeholder='物流编号'
      ></a-input>
      <a-button
          class="ml-5"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-popconfirm
          title="确定发货吗？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handlePrintProductCode"
      >
        <a-button
            class="ml-5"
        >发货</a-button>
      </a-popconfirm>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :loading="loading"
          :rowIndex="rowIndex"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
          @changeCheck="handleChangeCheck"
      />
    </div>
    <PrintProductCodePopup ref="printProductCodePopupEl"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/returnList/_components/TableList"
import PrintProductCodePopup from "@/views/cmsPage/checkManage/returnList/_components/PrintProductCodePopup"
import {SEND_PRODUCT_TYPE_LIST} from "@/views/cmsPage/checkManage/returnList/_data"
import {scanCodeWatch} from "@/untils/otherEvent"
import {getSendProductList} from "@/views/cmsPage/checkManage/returnList/_apis"

export default {
  components: {
    TableList,
    PrintProductCodePopup
  },
  data() {
    return {
      data: [],
      takeOVerProductTypeList: SEND_PRODUCT_TYPE_LIST,
      logisticsCompanyList: [],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        barCode: undefined,
        senderExpressNo: undefined,
        senderStatus: undefined,
        shortCode: undefined,
        orderNo: undefined
      },
      selectedArrays: [],
      rowIndex: []
    };
  },
  watch: {
  },
  async mounted() {
    // await this.getLogisticsCompany()
    await this.getList()
    /** 监听扫码 */
    scanCodeWatch(this.scanCodeEvent)
  },
  methods: {
    /** 改变 */
    handleChangeCheck(status, id) {
      this.rowIndex = []
      this.data.forEach((el, index) => {
        el.checkStatus = false
        if (el.senderLogisticsId === id) {
          if (status) {
            this.rowIndex.push(index)
          }
          el.checkStatus = status
        }
      })
    },
    /** 修改全选 */
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.data.forEach(item => {
          item.checkStatus = true
        })
      } else {
        this.data.forEach(item => {
          item.checkStatus = false
        })
      }
    },
    async scanCodeEvent(code) {
      if (code) {
        this.params.barCode = code
        await this.getList()
      }
    },
    /** 打印条码 */
    handlePrintProductCode() {
      let ids = []
      this.data.forEach(el => {
        if (el.checkStatus) {
          ids.push(el.id)
        }
      })
      if (ids.length < 1) return this.$message.warn('请选择要发货的产品')
      this.$refs.printProductCodePopupEl.show(ids.join(','))
      this.getList()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.rowIndex = []
      this.$loading.show()
      this.loading = true
      const res = await getSendProductList(this.params)
      this.loading = false
      this.$loading.hide()
      if (res.status != 200) return
      let list = res.data.records
      list.forEach(el => {
        el.checkStatus = false
      })
      this.data = list
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}
</style>
