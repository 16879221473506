<template>
  <div>
    <a-table
        class="table-box"
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        :rowClassName='rowClassName'
        @change="changePage"
    >
<!--   搜索   -->
      <div slot="selectedSlot" slot-scope="row">
        <div class="selected-box">
          <a-checkbox
              :disabled="row.senderStatus === 1"
              class="checked"
              :checked="row.checkStatus"
              @change="handleChangeCheckStatus($event, row)"
          ></a-checkbox>
        </div>
      </div>
<!--   拍品名字及图片   -->
      <div slot="itemProductNameAndImgSlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.productName }}</div>
        <div class="flex-start-center flex-wrap img-list mt-10">
          <img
              v-for="(item, index) in row.productImage"
              :key="index"
              :src="item"
              alt=""
              @click="handlePreviewImg(row.productImage, index)"
          >
        </div>
      </div>
<!--   订单   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <span @click="handleGoToOrder(row.orderNo)" class="cur-pot color-blue">{{ row.orderNo }}</span>
      </div>
<!--   收货类型   -->
      <div slot="itemTakeOverTypeSlot" slot-scope="row">
        <span
            :class="sendProductClassType.get(row.senderStatus)"
        >{{ computedSendProductTypeMapText(row.senderStatus) }}</span>
      </div>
<!--   保价   -->
      <div slot="itemInsuredSlot" slot-scope="row">
        <span>{{ row.ratingToBuyerPrice }}</span>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <div v-if="row.senderStatus === 1">
          <a-button type="primary" @click="handleShowExpressImg(row.expressShortUrl)">查看面单</a-button>
        </div>
      </div>
    </a-table>
    <ShowProductExpressNoPopup ref="showProductExpressNoPopupEl"/>
  </div>
</template>
<script>

import {
  SEND_PRODUCT_CLASS_TYPE,
  SEND_PRODUCT_LINE_TABLE_LIST,
  sendProductTypeMapText
} from "@/views/cmsPage/checkManage/returnList/_data"
import ShowProductExpressNoPopup from "@/views/cmsPage/checkManage/returnList/_components/ShowProductExpressNoPopup"

export default {
  props: ['pagination', 'list', 'loading', 'rowIndex'],
  components: {ShowProductExpressNoPopup},
  data() {
    return {
      tableList: SEND_PRODUCT_LINE_TABLE_LIST,
      sendProductClassType: SEND_PRODUCT_CLASS_TYPE,
    }
  },
  computed: {
    computedSendProductTypeMapText() {
      return (value) => {
        return sendProductTypeMapText(value)
      }
    },
  },
  methods: {
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 查看面单 */
    handleShowExpressImg(img) {
      this.$refs.showProductExpressNoPopupEl.show(img)
    },
    /** 设置每行类 */
    rowClassName(_, index) {
      return this.rowIndex.includes(index) ? 'bg-cadetblue' : ''
    },
    /** 改变check */
    handleChangeCheckStatus(e, row) {
      this.$emit('changeCheck', e.target.checked, row.senderLogisticsId)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    }
  }
}
</script>
<style lang="scss" scoped>
// 去除鼠标经过默认的背景颜色
.table-box::v-deep .ant-table-tbody>tr.ant-table-row:hover>td {
  background: none !important;
}
.selected-box {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .checked {
    padding-top: 30px;
    width: 100px;
    height: 100px;
    label {
      span {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.img-list {
  width: 100%;
  img {
    width: 100px;
    height: 100px;
  }
}
</style>