import {objArrMapObj} from "@/untils/otherEvent"

/** 列表数据 */
export const SEND_PRODUCT_LINE_TABLE_LIST = [
    { title: "选择数据", align: "center",scopedSlots: { customRender: "selectedSlot" }, width: 100 },
    { title: "裸币名称", align: "center", scopedSlots: { customRender: 'itemProductNameAndImgSlot'}, width: 500 },
    { title: "订单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "商品条码", align: "center", dataIndex: 'barCode' },
    { title: "收货状态", align: "center", scopedSlots: { customRender: 'itemTakeOverTypeSlot'} },
    { title: "物流单号", align: "center", dataIndex: 'senderExpressNo' },
    { title: "目标地址", align: "center", dataIndex: 'senderAddress', width: 200 },
    { title: "保价", align: "center", dataIndex: 'quotedPrice' },
    { title: "发货时间", align: "center", dataIndex: 'senderTime' },
    { title: "操作人", align: "center", dataIndex: 'sysAdmin' },
    { title: "操作", align: "center", scopedSlots: { customRender: 'itemActionSlot'} },
]

/** 是否发货 */
export const SEND_PRODUCT_TYPE_LIST = [
    { name: '已发货', value: 1 },
    { name: '未发货', value: -1 },
]

/** 是否收货对应的类 */
export const SEND_PRODUCT_CLASS_TYPE = new Map([
    [-1, 'color-red'],
    [1, 'color-green']
])

export const sendProductTypeMapText = (value) => {
    return objArrMapObj('value', 'name', SEND_PRODUCT_TYPE_LIST)[value]
}