var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 800, title: "打印预览" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return undefined
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c("div", { staticClass: "preview-content" }, [
            _c(
              "div",
              { staticClass: "print-product-list" },
              [
                false
                  ? _c(
                      "div",
                      { staticClass: "print-product-btn" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleConfirmPrintProduct }
                          },
                          [_vm._v("打印产品")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "VueEasyPrint",
                  {
                    ref: "vueEasyPrintByProductEl",
                    attrs: { "table-show": true, onePageRow: _vm.onePageRow }
                  },
                  [
                    false
                      ? _c("VueBarCode", {
                          staticClass: "ml-38",
                          attrs: {
                            value: _vm.codeData.barCode,
                            height: "63",
                            flat: true,
                            "text-margin": 0,
                            "font-size": "20",
                            format: "EAN13",
                            "font-options": "bold"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "print-product-text-list" },
                      _vm._l(_vm.codeData, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "print-product-item" },
                          [
                            _vm._v(
                              _vm._s(item.productName + "-" + item.barCodeNum)
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "print-express-order" },
              [
                _c(
                  "div",
                  { staticClass: "print-express-btn" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleConfirmPrintExpress }
                      },
                      [_vm._v("打印快递")]
                    )
                  ],
                  1
                ),
                _c(
                  "VueEasyPrint",
                  {
                    ref: "vueEasyPrintByExpressEl",
                    attrs: { "table-show": true, onePageRow: _vm.onePageRow }
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          width: _vm.printWidth + "mm",
                          height: _vm.printHeight + "mm"
                        }
                      },
                      [
                        _vm.codeData[0] && _vm.codeData[0].expressLabelUrl
                          ? _c("img", {
                              staticClass: "express-img",
                              style: {
                                width: _vm.printWidth + "mm",
                                height: _vm.printHeight + "mm"
                              },
                              attrs: {
                                src: _vm.codeData[0].expressLabelUrl,
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }